@font-face {
  font-family: Urbanist;
  src: url("Urbanist-Regular.6d541a45.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Urbanist;
  src: url("Urbanist-Medium.9f319208.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Urbanist;
  src: url("Urbanist-SemiBold.8b3a3a29.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Urbanist;
  src: url("Urbanist-Bold.eaa49b3f.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Urbanist;
  src: url("Urbanist-ExtraBold.d2e48bdc.ttf");
  font-weight: 800;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body, h1, h2, h3, p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

html {
  color: #131217;
  font-family: Urbanist, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

body {
  overflow-x: hidden;
}

.section-header {
  text-align: center;
  margin-bottom: 64px;
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}

@media (width >= 768px) {
  .section-header {
    margin-bottom: 96px;
    font-size: 60px;
  }
}

@media (width >= 1024px) {
  .section-header {
    font-size: 72px;
  }
}

.card {
  border: 2px solid #131217;
  border-radius: 24px;
  padding: 20px;
}

.card__text {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

@media (width >= 768px) {
  .card__text {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (width >= 1024px) {
  .card__text {
    font-size: 36px;
    line-height: 40px;
  }
}

.card__text--highlighted {
  white-space: nowrap;
  display: inline-block;
  position: relative;
}

.card__text--highlighted > svg {
  z-index: -1;
  stroke-width: 2px;
  position: absolute;
  bottom: -2px;
  left: -1px;
  right: -1px;
}

.page {
  background-color: #fffdf9;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: 12px;
}

@media (width >= 768px) {
  .container {
    padding-inline: 24px;
  }
}

@media (width >= 1024px) {
  .container {
    padding-inline: 48px;
  }
}

.header__container {
  align-items: center;
  height: 64px;
  display: flex;
}

@media (width >= 768px) {
  .header__container {
    height: 68px;
  }
}

@media (width >= 1024px) {
  .header__container {
    height: 78px;
  }
}

.header__logo {
  width: 40px;
  display: block;
}

@media (width >= 768px) {
  .header__logo {
    width: 48px;
  }
}

@media (width >= 1024px) {
  .header__logo {
    width: 64px;
  }
}

.hero {
  min-height: calc(100vh - 64px);
  padding-block: 20px;
}

.hero__container {
  grid-template-columns: repeat(1, 1fr);
  column-gap: 20px;
  display: grid;
}

@media (width >= 768px) {
  .hero__container {
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    row-gap: 20px;
    padding-block: 40px;
  }
}

@media (width >= 1024px) {
  .hero__container {
    grid-template-columns: repeat(12, 1fr);
    row-gap: 12px;
  }
}

.hero__title {
  margin-bottom: 12px;
}

.hero__text-container {
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  display: flex;
}

@media (width >= 768px) {
  .hero__text-container {
    grid-column: 4 / span 3;
    margin-bottom: 0;
  }
}

@media (width >= 1024px) {
  .hero__text-container {
    grid-column: 1 / 6;
    align-self: start;
  }
}

.hero__text {
  color: #7e7d7f;
  font-weight: 600;
}

.hero__dashboard-container {
  grid-column: 1 / -1;
}

@media (width >= 1024px) {
  .hero__dashboard-container {
    grid-area: 1 / 7 / span 2 / span 6;
  }
}

.hero__dashboard {
  border-radius: 4px;
  width: 100%;
  box-shadow: -15px 15px 30px #0000004d;
}

@media (width >= 768px) {
  .hero__dashboard {
    border-radius: 12px;
  }
}

@media (width >= 1024px) {
  .hero__dashboard {
    border-radius: 16px;
    width: 1000px;
  }
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}

@media (width >= 768px) {
  .hero-title {
    grid-column: span 3;
    margin-bottom: 0;
    font-size: 60px;
  }
}

@media (width >= 1024px) {
  .hero-title {
    grid-column: 1 / 6;
    align-self: end;
  }
}

.hero-title__text {
  display: block;
  overflow: hidden;
}

.tags {
  flex-wrap: wrap;
  gap: 4px;
  display: flex;
}

.tags__tag {
  background: #ecebf0;
  border-radius: 15px;
  height: max-content;
  padding: 4px 16px;
  font-size: 14px;
  font-weight: 600;
}

@media (width >= 768px) {
  .tags__tag {
    font-size: 16px;
  }
}

.problem-metrics {
  padding-block: 80px;
}

.problem-metrics__the-problem {
  margin-bottom: 80px;
}

.the-problem__text {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

.the-problem__paragraph {
  max-width: 900px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

@media (width >= 768px) {
  .the-problem__paragraph {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (width >= 1024px) {
  .the-problem__paragraph {
    font-size: 36px;
    line-height: 40px;
  }
}

.metrics {
  align-items: center;
  display: flex;
}

.metrics__cards {
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  display: grid;
}

@media (width >= 1024px) {
  .metrics__cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

.our-solution__cards {
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  display: grid;
}

@media (width >= 1024px) {
  .our-solution__cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

.contact {
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-block: 80px;
  display: flex;
}

.contact__container {
  grid-template-columns: repeat(1, 1fr);
  align-content: center;
  gap: 40px;
  display: grid;
}

@media (width >= 768px) {
  .contact__container {
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
  }
}

@media (width >= 1024px) {
  .contact__container {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (width >= 768px) {
  .contact__text-container {
    grid-column: 1 / 4;
  }
}

@media (width >= 1024px) {
  .contact__text-container {
    grid-column: 1 / 6;
  }
}

.contact__text {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

@media (width >= 768px) {
  .contact__text {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (width >= 1024px) {
  .contact__text {
    font-size: 36px;
    line-height: 40px;
  }
}

.contact__email {
  text-align: center;
  color: #7e7d7f;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

.contact__form {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

@media (width >= 768px) {
  .contact__form {
    grid-column: 4 / -1;
  }
}

@media (width >= 1024px) {
  .contact__form {
    grid-column: 7 / -1;
  }
}

.contact-form__field {
  flex-direction: column;
  gap: 2px;
  display: flex;
}

.contact-form__label {
  color: #7e7d7f;
  font-size: 18px;
  font-weight: 600;
}

.contact-form__input {
  background: none;
  border: 2px solid #131217;
  border-radius: 12px;
  height: 40px;
  padding-inline: 12px;
  font-family: inherit;
  font-size: 16px;
}

.contact-form__input:focus {
  border-color: #379392;
  outline: none;
}

.contact-form__submit {
  color: #fff;
  background: #379392;
  border-radius: 12px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

@media (width >= 768px) {
  .contact-form__submit {
    font-size: 16px;
    line-height: 24px;
  }
}
/*# sourceMappingURL=index.1a0857ca.css.map */
