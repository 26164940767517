html {
  font-family: "Urbanist", Arial, Helvetica, sans-serif;
  color: $text-main;
  font-size: 16px;
  line-height: 1.5;
}

body {
  overflow-x: hidden;
}
