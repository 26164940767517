.hero {
  padding-block: 20px;
  min-height: calc(100vh - 64px);

  &__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;

    @include onTablet {
      grid-template-columns: repeat(6, 1fr);
      align-items: center;
      row-gap: 20px;
      padding-block: 40px;
    }

    @include onDesktop {
      grid-template-columns: repeat(12, 1fr);
      row-gap: 12px;
    }
  }

  &__title {
    margin-bottom: 12px;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    @include onTablet {
      margin-bottom: 0;

      grid-column: 4 / span 3;
    }

    @include onDesktop {
      grid-column: 1 / 6;
      align-self: start;
    }
  }

  &__text {
    font-weight: 600;
    color: $text-secondary;
  }

  &__dashboard-container {
    grid-column: 1 / -1;

    @include onDesktop {
      grid-row: 1 / span 2;
      grid-column: 7 / span 6;
    }
  }

  &__dashboard {
    width: 100%;
    box-shadow: -15px 15px 30px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    @include onTablet {
      border-radius: 12px;
    }

    @include onDesktop {
      width: 1000px;
      border-radius: 16px;
    }
  }
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;

  @include onTablet {
    grid-column: span 3;

    margin-bottom: 0;

    font-size: 60px;
  }

  @include onDesktop {
    grid-column: 1 / 6;
    align-self: end;
  }

  &__text {
    display: block;

    overflow: hidden;
  }
}
