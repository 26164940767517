.header {
  &__container {
    display: flex;
    align-items: center;
    height: 64px;

    @include onTablet {
      height: 68px;
    }

    @include onDesktop {
      height: 78px;
    }
  }

  &__logo {
    display: block;
    width: 40px;

    @include onTablet {
      width: 48px;
    }

    @include onDesktop {
      width: 64px;
    }
  }
}
