@font-face {
  font-family: "Urbanist";
  src: url(../fonts/Urbanist-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url(../fonts/Urbanist-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url(../fonts/Urbanist-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url(../fonts/Urbanist-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url(../fonts/Urbanist-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}
