.container {
  max-width: 1280px;
  padding-inline: 12px;
  margin: 0 auto;

  @include onTablet {
    padding-inline: 24px;
  }

  @include onDesktop {
    padding-inline: 48px;
  }
}
