.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-block: 80px;

  &__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-content: center;
    gap: 40px;

    @include onTablet {
      grid-template-columns: repeat(6, 1fr);
      align-items: center;
    }

    @include onDesktop {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__text-container {
    @include onTablet {
      grid-column: 1 / 4;
    }

    @include onDesktop {
      grid-column: 1 / 6;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;

    @include onTablet {
      font-size: 30px;
      line-height: 36px;
    }

    @include onDesktop {
      font-size: 36px;
      line-height: 40px;
    }
  }

  &__email {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;

    font-size: 20px;
    text-align: center;
    color: $text-secondary;
    text-decoration: none;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include onTablet {
      grid-column: 4 / -1;
    }

    @include onDesktop {
      grid-column: 7 / -1;
    }
  }
}

.contact-form {
  &__field {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__label {
    font-size: 18px;
    font-weight: 600;
    color: $text-secondary;
  }

  &__input {
    height: 40px;
    padding-inline: 12px;

    font-family: inherit;
    font-size: 16px;

    background: transparent;
    border: 2px solid $text-main;
    border-radius: 12px;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }

  &__submit {
    padding: 12px 16px;

    font-size: 14px;
    font-weight: 600;
    color: $primary-foreground;
    line-height: 20px;

    background: $primary;
    border-radius: 12px;

    @include onTablet {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
