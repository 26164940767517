* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}
